
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { graphql } from '@/__generated__';
import { ViewerLogInPayload, ViewerLogInWithPasswordInput } from '@/__generated__/graphql';
import { useAuth } from '@/providers/auth';
import { isNumeric } from '@/utils/util';
import { useMutation } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { Button, Form, Input } from 'antd';
import { useRouter } from 'next/compat/router';
import Image from 'next/image';
import backgroundImage from '@/../public/static/images/background.jpg';
import { useSnackbar } from 'notistack';
import { loginWithGoogle } from '@/providers/auth/firebase';
import { useCallback } from 'react';
import iconGoogle from '@/../public/static/icons/icon_google.png';
import styles from './style.module.css';
// ----------------------------------------------------------------------
const ContentStyle = styled('div')(() => ({
    maxWidth: 500,
    margin: 'auto',
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 2,
    position: 'relative'
}));
const MutationLogin = graphql(`
  mutation LoginViewLogInWithPasswordMutation($input: ViewerLogInWithPasswordInput!) {
    viewerLogIn(input: { withPassword: $input }) {
      viewer {
        ...Viewer
      }
      userErrors {
        code
        field
        message
        param {
          value
        }
      }
    }
  }
`);
const MutationLoginGoogle = graphql(`
  mutation GoogleLoginButtonLoginMutation($input: ViewerLogInWithFirebaseEmailInput!) {
    viewerLogIn(input: { withFirebaseEmail: $input }) {
      viewer {
        ...Viewer
      }
      userErrors {
        code
        field
        message
        param {
          value
        }
      }
    }
  }
`);
export default function LoginPage() {
    const router = useRouter();
    const { writeQueryViewer } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [login, { loading }] = useMutation(MutationLogin, {
        context: { v2: true },
        onCompleted: (res: {
            viewerLogIn: ViewerLogInPayload;
        }) => {
            const viewer = res?.viewerLogIn?.viewer;
            if (viewer) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                writeQueryViewer({ viewer, loginSession: null });
                void router.replace('/');
            }
            if (res?.viewerLogIn?.userErrors?.length) {
                enqueueSnackbar(`Tài khoản hoặc mật khẩu không đúng - ${res.viewerLogIn.userErrors[0]?.message}`, { variant: 'error' });
            }
        }
    });
    const [loginGoogle, { loading: loadingGoogle }] = useMutation(MutationLoginGoogle, {
        context: { v2: true },
        onCompleted: (res: {
            viewerLogIn: ViewerLogInPayload;
        }) => {
            const viewer = res?.viewerLogIn?.viewer;
            if (viewer) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                writeQueryViewer({ viewer, loginSession: null });
                void router.replace('/');
            }
            if (res?.viewerLogIn?.userErrors?.length) {
                enqueueSnackbar(`Tài khoản hoặc mật khẩu không đúng - ${res.viewerLogIn.userErrors[0]?.message}`, { variant: 'error' });
            }
        }
    });
    const onFinish = (values: ViewerLogInWithPasswordInput) => {
        let iden = values.identifier?.trim();
        if (isNumeric(iden)) {
            if (iden?.startsWith('84')) {
                iden = iden.replace('84', '+84');
            }
            if (iden?.startsWith('0')) {
                iden = iden.replace('0', '+84');
            }
            if (!iden?.startsWith('0') && !iden?.startsWith('+84') && !iden?.startsWith('84')) {
                iden = `+84${iden}`;
            }
            iden = iden.replace(' ', '');
        }
        void login({
            variables: { input: { ...values, identifier: iden } }
        });
    };
    const handleClickGoogle = useCallback(() => {
        void loginWithGoogle().then((rs) => {
            console.log(rs?.user);
            if (rs?.user?.accessToken) {
                void loginGoogle({
                    variables: {
                        input: {
                            token: rs?.user?.accessToken
                        }
                    }
                });
            }
        });
    }, [loginGoogle]);
    return (<div>
      <ContentStyle className="md:mr-[270px]">
        <div style={{
            backgroundColor: '#fff',
            height: '90vh',
            borderRadius: '8px',
            padding: '16px'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column'
        }}>
            <div style={{
            backgroundColor: '#fff',
            fontSize: '40px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '-160px'
        }}>
              FLYER
            </div>
            <Form name="Login" initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off" style={{
            marginTop: '50px'
        }} layout="vertical">
              <Form.Item<ViewerLogInWithPasswordInput> label="Identifier" name="identifier" rules={[{ required: true, message: 'Please input your username!' }]}>
                <Input size="large"/>
              </Form.Item>

              <Form.Item<ViewerLogInWithPasswordInput> label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password size="large"/>
              </Form.Item>

              <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" htmlType="submit" style={{ width: '300px', height: '40px', fontWeight: 'bold', marginTop: '16px' }} className={styles.buttonCustom} loading={loading || loadingGoogle}>
                  LOGIN
                </Button>
                <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'center' }}>
                  <Image src={iconGoogle} alt="google" width={40} height={40} style={{
            cursor: 'pointer'
        }} onClick={handleClickGoogle}/>
                </div>
              </Form.Item>
            </Form>
            <div style={{
            paddingBottom: '16px',
            fontSize: '10px',
            color: '#b4b4b4'
        }}>
              Bảo mật tài khoản, không chia sẻ với bất kỳ ai
            </div>
          </div>
        </div>
      </ContentStyle>
      <Image src={backgroundImage} alt="Picture of the author" style={{
            position: 'fixed',
            objectFit: 'cover',
            overflow: 'hidden',
            width: '100%',
            height: '100vh',
            top: 0,
            zIndex: 1
        }}/>
    </div>);
}

    async function __Next_Translate__getStaticProps__19504c8d1c9__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19504c8d1c9__ as getStaticProps }
  